import ContentAgreement from "./ContentAgreement";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { date, month, year, formatBirthdate } from "../utils/helper";
import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import { setMemberInfo } from "store/member/memberSlice";
import { useSelector, useDispatch } from "react-redux";
import { VIP_REGISTER_NAME } from "services/users";
import { useLocation } from "react-router-dom";
import PopupCancelMembership from "./Popup/PopupCancelMembership";
import useHandleLogout from "hooks/useHandleLogout";

const MemberAgreement = (props) => {
  const { maxContent, isCoupon } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = useHandleLogout();
  const {} = useAuth0();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const invalidMemberInfo = useSelector(
    (state) => state.invalidMember.invalidMemberInfo
  );
  const [disabled, setDisabled] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const userInfo = location?.pathname === "/user-info";
  const [params, setParams] = useState({
    agree: "",
    newsletter: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (memberInfo?.first_name && memberInfo?.member_no) {
      setDisabled(true);
      setParams(
        Object.assign({}, memberInfo, {
          agree: true,
          newsletter: true,
        })
      );
    } else {
      setDisabled(false);
      setParams({
        agree: "",
        newsletter: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        day: "",
        month: "",
        year: "",
      });
    }
  }, [memberInfo]);

  useEffect(() => {
    if (invalidMemberInfo?.error) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [invalidMemberInfo]);

  const handleChange = (type, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [type]: value,
    }));
  };

  const handleRegister = () => {
    if(params.first_name == '' || params.last_name == '' || params.agree == '' || params.day == '' || params.month == '' || params.year == '' || params.newsletter == ''){
      toast.error(t("missing_fields"));
      console.error("Required registration user fields are missing");
      return;
    }

    if(params.first_name == null || params.last_name == null || params.agree == null || params.day == null || params.month == null || params.year == null || params.newsletter == null){
      toast.error(t("missing_fields"));
      console.error("Required registration user fields are missing");
      return;
    }

    if(/\s/.test(params.first_name)){
      toast.error(t("first_name_space"));
      console.error("First name Spaces are not allowed in this field");
      return;
    }
    if(/\s/.test(params.middle_name)){
      toast.error(t("middle_name_space"));
      console.error("Spaces are not allowed in Middle name field");
      return;
    }
    if(/\s/.test(params.last_name)){
      toast.error(t("last_name_space"));
      console.error("Last name Spaces are not allowed in this field");
      return;
    }

    if((params.first_name && params.first_name.length > 50) || (params.last_name && params.last_name.length > 50)){
      toast.error(t("name_len"));
      return;
    }

    if(params.middle_name && params.middle_name.length > 50){
      toast.error(t("name_len"));
      return;
    }

    params.month = params?.month < 10 ? "0" + params?.month : params?.month;
    params.day = params?.day < 10 ? "0" + params?.day : params?.day;
    const body = {
      first_name:params.first_name,
      middle_name:params.middle_name,
      last_name:params.last_name,
      birthdate: [params?.year, params?.month, params?.day].join("-"),
      is_withdraw:0,
      withdraw_date:null,
      is_vip:1
      // year_of_birth:params.year,
      // month_of_birth:params.month,
      // day_of_birth:params.day
    };
    if (memberInfo?.first_name) {
      navigate("/membership-privilege");
    } else {
      VIP_REGISTER_NAME(body)
        .then((res) => {
          const getBirthdate = formatBirthdate(body?.birthdate).split("-");
          dispatch(
            setMemberInfo({
              isMember: true,
              day: getBirthdate[0] || "",
              month: getBirthdate[1] || "",
              year: getBirthdate[2] || "",
              ...body,
            })
          );
          if (isCoupon) navigate("/new-member-coupon");
          else navigate("/membership-privilege");
        })
        .catch((error) => {
          toast.error(error.data || "Some error occured");
          // sent error to sentry
          if (typeof error === "object") {
            console.error("VIP registration name & dob", JSON.stringify(error));
          } else {
            console.error("VIP registration name & dob", error);
          }
        });
    }
  };

  const CustomSelect = (props) => {
    const { items, type } = props;
    return (
      <select
        defaultValue=""
        value={params[type]}
        onChange={(e) => handleChange(type, e?.target?.value)}
        disabled={disabled}
        className={`${!params[type] ? 'text-zinc-400' : 'text-black'} ${disabled && '!bg-[#dbdada] text-zinc-400 !border-[#a2a2a2] opacity-100'} w-full rounded-[5px] text-sm font-normal border-[#c4c4c4]`}
        name="date">
        <option value="" disabled>
          {t(type)}
        </option>
        {items().map((item) => (
          <option value={item} className="text-black">{item}</option>
        ))}
      </select>
    );
  };

  return (
    <div className="px-2">
      <div
        className={`flex flex-col self-stretch px-4 p-4 mt-4 bg-white rounded-lg border border-solid border-zinc-300 text-neutral-700 ${
          maxContent ? "" : "max-h-card overflow-x-scroll"
        }`}
      >
        <div className="justify-center px-4 py-2 text-sm leading-5 text-white bg-neutral-700">
          {t("overseas_customer")}
        </div>
        <div className="mt-4 leading-5">
          <ContentAgreement />
        </div>
      </div>
      {maxContent && (
        <div
          onClick={() => navigate("/membership")}
          className="justify-center cursor-pointer hover:scale-105 items-center self-center px-16 py-3 m-auto mt-4 w-full text-sm text-center whitespace-nowrap rounded-sm border border-solid border-neutral-700"
        >
          Return to previous page
        </div>
      )}
      {!maxContent && (
        <>
          <div className="flex gap-2 pr-5 mt-4 justify-center items-center">
            <input
              id="myCheckbox"
              type="checkbox"
              checked={params?.agree}
              disabled={disabled}
              className="w-5 h-5 bg-white rounded-sm border border-solid border-zinc-300"
              onChange={(e) => handleChange("agree", e?.target?.checked)}
            />
            <label
              className="text-sm font-light text-neutral-700"
              htmlFor="myCheckbox"
            >
              {t("member_agreement")}
            </label>
          </div>
          <div className="px-4 mt-4 text-xs font-light leading-4 text-neutral-400">
            {t("member_agreement_note")}
          </div>
          {/* <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder="Yamada Hanako"
              defaultValue={params?.name}
              onBlur={(e) => handleChange("name", e?.target?.value)}
            />
          </div> */}
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("first_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("first_name")}
              defaultValue={params?.first_name}
              onBlur={(e) => handleChange("first_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
            {t("middle_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("middle_name")}
              defaultValue={params?.middle_name}
              onBlur={(e) => handleChange("middle_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
            {t("last_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("last_name")}
              defaultValue={params?.last_name}
              onBlur={(e) => handleChange("last_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-2 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full ext-left">
              {t("date_birth")}
            </div>
            <div className="flex gap-2 justify-between">
              <CustomSelect items={date} type="day" />
              <CustomSelect items={month} type="month" />
              <CustomSelect items={year} type="year" />
            </div>
          </div>
          <div className="flex px-4 mt-4 gap-2 w-full text-sm font-light text-neutral-700">
            <input
              id="newsletter"
              type="checkbox"
              checked={params?.newsletter}
              disabled={disabled}
              className="w-5 h-5 bg-white rounded-sm border border-solid border-zinc-300"
              onChange={(e) => handleChange("newsletter", e?.target?.checked)}
            />
            <label className="grow cursor-pointer" htmlFor="newsletter">
              {t("member_newsletter")}
            </label>
          </div>
          <div className="px-4 w-full">
            <div
              className={`frame ${
                disabledButton && "opacity-80 cursor-not-allowed"
              }`}
              style={{
                background: disabledButton && "#a4a5a6",
                boxShadow: disabledButton && "none",
                border: disabledButton && "solid 1px #a2a2a2",
              }}
              onClick={() => !disabledButton && handleRegister()}
            >
              <div className="text-wrapper font-hankyu font-bold">
                {isCoupon
                  ? t("get_coupon")
                  : userInfo
                  ? "Back"
                  : t("new_member")}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 w-full">
            {userInfo ? (
              <p
                onClick={() => setOpenPopup(true)}
                className="underline text-[#7f4d3a] font-semibold text-center hover:scale-105 cursor-pointer text-sm"
              >
                Cancel Membership
              </p>
            ) : (
              <p
                onClick={handleLogout}
                className="underline text-[#7f4d3a] font-semibold text-center hover:scale-105 cursor-pointer text-xs"
              >
                Back to Login
              </p>
            )}
          </div>
        </>
      )}

      <PopupCancelMembership
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
    </div>
  );
};

export default MemberAgreement;
